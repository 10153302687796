<script>
import countTo from "vue-count-to";
import axios from 'axios';
const moment = require('moment');
/**
 * Sales-analytics component
 */
export default {
    components: {
        countTo
    },
    data() {
        return {
            series: [],
            chartOptions: {

                chart: {
                    stacked: false,
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: [0, 2, 4],
                    curve: 'smooth'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '30%'
                    }
                },
                colors: ['#5b73e8', '#84f6fa', '#f1b44c'],
                fill: {
                    opacity: [0.85, 0.25, 1],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: "vertical",
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                labels: ['01/02/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003', '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003', '12/02/2003', '12/02/2003'],


                xaxis: {
                    type: 'datetime',

                    labels: {
                        format: 'MMM'
                    },


                    tooltip: {
                        x: {
                            format: 'DD'
                        }
                    },


                },
                yaxis: {
                    title: {
                        text: 'Per Bulan',
                    },
                    labels: {
                        text: 'MMM'
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,

                    y: {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {

                                return y.toFixed(0) + " points";
                            }
                            return y;

                        },

                    }
                },
                grid: {
                    borderColor: '#f1f1f1'
                }
            },
            viewPelangganBaru: {
                bulan: "",
                pelangganBaru: 0,
                growthPelangganBaru: 0
            },
            growthPelangganBaru: 0,
            DataSetApePelangganBaru: [],
            DataSetApePelangganMasuk: [],
            viewPelangganMasuk: 0,
            viewPelangganMasukEndval: 0,
            viewPelangganBaruEndval: 0,
            konversiGrowth: 0,
            allDataSet: [],
            clientGrupDropdown: [],
            labelGroupDropdown: "All",
        };
    },
    async mounted() {


        await this.getPelangganDataLocalByClientGrup()
        await this.getChartDataPelangganMasuk()
        await this.updateChart()


    },

    methods: {
        async getChartDataPelangganBaru() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset?$limit=12&dashBoardLoad=true').then(response => {



                let looping = 0
                for (let index2 = 0; index2 < response.data.data.length; index2++) {
                    if (this.DataSetApePelangganBaru.length < 12) {
                        this.DataSetApePelangganBaru.push(response.data.data[index2].pelangganBaru)


                    } else if (looping < 12) {
                        this.DataSetApePelangganBaru[index2] = this.DataSetApePelangganBaru[index2] + response.data.data[index2].pelangganBaru
                    }

                    this.growthPelangganBaru = this.growthPelangganBaru + parseInt(response.data.data[index2].pelangganBaru)

                }


            });




        },
        async getChartDataPelangganMasuk() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-charts?customerChart=true').then(response => {


                this.viewPelangganMasuk = parseInt(response.data.viewDataPelanggan.totalPelangganMasuk)

                this.viewPelangganMasukEndval = response.data.viewDataPelanggan.totalPelangganMasuk


                // for (let index = 0; index < 12; index++) {
                //     this.DataSetApePelangganMasuk.push(response.data.dataPelanggan.data[index].pelangganBaruMasuk)

                // }

              // this.konversiGrowth = this.growthPelangganBaru / this.viewPelangganMasukEndval * 100


            });
        },

     
        async getChartDataPelangganMasukNewTable() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-charts?customerChart=true').then(response => {

                this.viewPelangganMasuk = response.data.viewDataPelanggan
                // for (let index = 0; index < 12; index++) {
                //     this.DataSetApePelangganMasuk.push(response.data.dataPelanggan.data[index].pelangganBaruMasuk)

                // }
               // this.konversiGrowth = this.growthPelangganBaru / this.viewPelangganMasukEndval * 100
            });
        },

        async getPelangganDataLocalByClientGrup() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'dashboard-analytic-dataset?$limit=12&newApexChart=true').then(async (response) => {
                this.DataSetApePelangganMasuk = []
                this.DataSetApePelangganBaru = response.data.returnDataAllClient.pelangganbaru

                this.allDataSet = response.data
                this.clientGrupDropdown = response.data.clientGrupArray
                this.labelGroupDropdown = "All Client Grup"
               
                this.viewPelangganBaruEndval = response.data.returnDataAllClient.totalpelangganBaru
                this.DataSetApePelangganMasuk = response.data.returnDataAllClient.pelangganMasuk                
                this.viewPelangganMasuk = parseInt(response.data.returnDataAllClient.totalPelanggaMasuk)
                this.viewPelangganMasukEndval = response.data.returnDataAllClient.totalPelanggaMasuk
                this.konversiGrowth = this.viewPelangganBaruEndval / this.viewPelangganMasuk * 100
               

            })
        },



        async updateChart() {


            this.series = []

            await this.series.push({
                name: 'Pelanggan Baru',
                type: 'column',
                data: this.DataSetApePelangganBaru
            })

            await this.series.push({
                name: 'Pelanggan Masuk',
                type: 'area',
                data: this.DataSetApePelangganMasuk
            })




            var c = moment().month(0).add(1, 'days').format('MM/DD/YYYY');
            var start = moment().month(0).startOf('month').add(1, 'days').format('MM/DD/YYYY')
            var count = 0


            const newData2 = this.chartOptions.labels.map(() => {

                if (start == c) {

                    c = moment().month(count).startOf('month').add(1, 'days').format('MM/DD/YYYY')
                    count = count + 1
                }
                else if (count != 12) {
                    c = moment().month(count).startOf('month').add(1, 'days').format('MM/DD/YYYY')
                    count = count + 1
                }
                else {

                    return c
                }

                return c

            })
            // In the same way, update the series option

            this.chartOptions = {
                labels: newData2
            }

        },
        async updateChart1() {
            this.series = []
            await this.series.push({
                name: 'Pelanggan Masuk',
                type: 'area',
                data: this.DataSetApePelangganMasuk
            })
        },
        async getDataByDropdown(test) {

            this.labelGroupDropdown = test
        },





    },
    watch: {
        'labelGroupDropdown': {

            async handler(test) {

                let currentMonth = moment().startOf('month').format('M')
                let indexBulan = currentMonth -1 

                console.log("indexBulan",indexBulan);
                console.log("current",currentMonth);
                for (let index = 0; index < this.allDataSet.arrayHasil.length; index++) {

                    if (this.labelGroupDropdown == this.allDataSet.arrayHasil[index].groupcode) {
                        console.log("sini om ");
                        this.DataSetApePelangganBaru = this.allDataSet.arrayHasil[index].pelangganbaru
                        console.log(this.allDataSet.arrayHasil[index]);
                        this.DataSetApePelangganMasuk =  this.allDataSet.arrayHasil[index].pelangganMasuk

                        this.viewPelangganBaruEndval = parseInt(this.allDataSet.arrayHasil[index].totalpelangganBaru[indexBulan])
                        this.viewPelangganBaruEndval = parseInt(this.viewPelangganBaruEndval)
                        
                        this.viewPelangganMasuk = this.allDataSet.arrayHasil[index].totalPelangganMasuk[indexBulan]
                        this.viewPelangganMasuk = parseInt(this.viewPelangganMasuk)

                       
                        this.konversiGrowth = parseInt(this.viewPelangganBaruEndval) / parseInt(this.viewPelangganMasuk) * 100
                        this.konversiGrowth = parseInt(this.konversiGrowth)
                        this.updateChart()
                    }


                }
                if (test == "All Client Grup") {
                    
                    this.getPelangganDataLocalByClientGrup()
                    this.updateChart()

                }

            },
        },
    },

};
</script>

<template>
    <div class="col-md-6">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <b-dropdown variant="white" toggle-class="text-reset p-0" menu-class="dropdown-menu-end"
                        v-bind:text="(labelGroupDropdown == 'All' ? 'All' : labelGroupDropdown)">
                        <template v-slot:button-content>
                            <span class="fw-semibold">Client Grup:</span>
                            <span class="text-muted">
                                {{ labelGroupDropdown }}
                                <i class="mdi mdi-chevron-down ms-1"></i>
                            </span>
                        </template>
                        <b-dropdown-item href="#" @click="getPelangganDataLocalByClientGrup">All Client
                            Grup</b-dropdown-item>
                        <b-dropdown-item href="#" v-for="clientGrup in clientGrupDropdown" :key="clientGrup"
                            @click="getDataByDropdown(clientGrup)">{{ clientGrup }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <h4 class="card-title mb-4">Pelanggan Baru Analytics</h4>

                <div class="mt-1">
                    <ul class="list-inline main-chart mb-0">
                        <li class="list-inline-item chart-border-left me-0 border-0">
                            <h3 class="text-primary">

                                <span data-plugin="counterup" class="font-size-22">
                                    <countTo :startVal="0" :endVal="viewPelangganBaruEndval" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-14 ms-3">Pelanggan Baru</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0 ">
                            <h3>
                                <span data-plugin="counterup" class="font-size-22">
                                    <countTo :startVal="0" :endVal="viewPelangganMasuk" :duration="2000"></countTo>
                                </span>
                                <span class="text-muted d-inline-block font-size-14 ms-3">Pelanggan Masuk</span>
                            </h3>
                        </li>
                        <li class="list-inline-item chart-border-left me-0 ">
                            <h3>
                                <span data-plugin="counterup" class="font-size-22" :class="{
                                    'text-success me-1': konversiGrowth > 0,
                                    'text-danger me-1': konversiGrowth < 0,
                                    'text-info me-1': konversiGrowth === 0
                                }">
                                    <countTo :startVal="0" :endVal="konversiGrowth" :duration="2000" :decimals="2">
                                    </countTo>%
                                </span>
                                <span class="text-muted d-inline-block font-size-15 ms-1"> Konversi Pelanggan Baru</span>
                            </h3>
                        </li>
                    </ul>
                </div>

                <div class="mt-3">
                    <apexchart id="chart" type="line" class="apex-charts" dir="ltr" height="339" :options="chartOptions"
                        :series="series"></apexchart>
                </div>
            </div>
            <!-- end card-body-->
        </div>
        <!-- end card-->
    </div>






    <!-- end col-->
</template>
