<script>
import countTo from "vue-count-to";
import axios from 'axios';
//const moment = require('moment');

/**
 * Stat component
 */
export default {
    components: {
        countTo
    },
    data() {
        return {
            series: [{
                data: [1, 3, 5, 7]
            }],
            chartOptions: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    },
                    offsetX: 50
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    },
                    chart: {
                        offsetX: 50
                    },
                }
            },
            growthChartOptions: {
                fill: {
                    colors: ["#84f6fa"]
                },
                chart: {
                    type: "bar",
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    bar: {
                        columnWidth: "50%"
                    }
                },
                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                xaxis: {
                    crosshairs: {
                        width: 1
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }


            },
            orderseries: [99],
            orderRadial: {
                fill: {
                    colors: ["#34c38f"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        },

                    }
                },

            },
            customerseries: [25],
            customerRadial: {
                fill: {
                    colors: ["#5b73e8"]
                },
                chart: {
                    sparkline: {
                        enabled: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "60%"
                        },
                        track: {
                            margin: 0
                        },
                        dataLabels: {
                            show: false
                        },
                        value: {
                            fontSize: "30px",
                            show: true
                        }
                    }
                },

            },
            testing: [],

            viewPelangganBaru: {
                pelangganBaru: 0,
                growthPelangganBaru: 0
            },
            growthPelangganBaru: {

            },

            invoices: {
                totalInvoice: 0,
                totalPaidInvoices: 0
            },
            persenPaid: [],
            persenGrowthInvoice: 0.00,
            persenGrowthInvoicePaid: 0.00,
            persenGrowthPelangganBaru: 0.00,
            clientgrupAdmin: [],
            viewTotalInvoice: 0,
            viewTotalPaidInvoices: 0,

            viewGrowthMountly: {
                GrowthMountlytotal: 0,
                GrowthMountlyPaided: 0,
            },
            clientGrupGrowthMountly: {
            },
            pelangganMasukDataSet: {
                viewPelangganMasuk: [],
                viewPelangganMasukEndval: 0,
                persenGrowthPelangganMasuk: 0,
            }
        };


    },

    async mounted() {
        // await this.payLoadData()

    },

    async created() {
        await this.payLoadData()
    },

    methods: {

        async payLoadData() {
            await this.getChartDataPelangganMasuk()
            await this.getRecentPelangganBaru()
            await this.getInvoice()
            await this.getGrowthMountly()

        },

        async getRecentPelangganBaru() {


            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?widget=true').then(response => {
                this.growthPelangganBaru = response.data.dataPelanggan
                this.viewPelangganBaru.pelangganBaru = this.viewPelangganBaru.pelangganBaru + response.data.dataPelanggan.oneMountData
                this.viewPelangganBaru.growthPelangganBaru = this.viewPelangganBaru.growthPelangganBaru + response.data.dataPelanggan.TwoMountData
                console.log("test",this.viewPelangganBaru.pelangganBaru);
            });




        },

        async getInvoice() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'invoice' + '?invoiceTotal=true').then(response => {

                this.viewTotalInvoice = response.data.dataBulanIni.total
                this.viewTotalPaidInvoices = response.data.dataBulanIni.settlement

                // this.clientGrupGrowthMountly =  response.data.dataBulanLalu

                this.viewGrowthMountly.GrowthMountlytotal = response.data.dataBulanLalu.total
                this.viewGrowthMountly.GrowthMountlyPaided = response.data.dataBulanLalu.settlement

            });

            this.persenPaid.push(this.viewTotalPaidInvoices / this.viewTotalInvoice * 100)
            this.customerseries = []
            this.customerseries.push(this.viewTotalPaidInvoices / this.viewTotalInvoice * 100)

        },



        async getGrowthMountly() {

            let hitungPersen = this.viewTotalInvoice / this.viewGrowthMountly.GrowthMountlytotal * 100 - 100
            hitungPersen = parseFloat(hitungPersen)

            this.persenGrowthInvoice = hitungPersen
            let hitungPersenPaid = this.viewTotalPaidInvoices / this.viewGrowthMountly.GrowthMountlyPaided * 100 - 100

            hitungPersenPaid = parseFloat(hitungPersenPaid)
            this.persenGrowthInvoicePaid = hitungPersenPaid

            this.persenGrowthPelangganBaru = this.viewPelangganBaru.pelangganBaru / this.viewPelangganBaru.growthPelangganBaru * 100 - 100

            this.persenGrowthPelangganBaru = parseFloat(this.persenGrowthPelangganBaru)


            if (this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasukBulanLalu == 0) {
                this.pelangganMasukDataSet.persenGrowthPelangganMasuk = this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasuk * 100

            }
            else {

                this.pelangganMasukDataSet.persenGrowthPelangganMasuk = this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasuk / this.pelangganMasukDataSet.viewPelangganMasuk.pelangganMasukBulanLalu * 100 - 100

            }

            this.pelangganMasukDataSet.persenGrowthPelangganMasuk = parseFloat(this.pelangganMasukDataSet.persenGrowthPelangganMasuk)

        },


       async routePushInvoices(){
        this.$router.push('/invoices/list')
       },


        async getChartDataPelangganMasuk() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'custumer-charts?customerChart=true').then(response => {

                this.pelangganMasukDataSet.viewPelangganMasuk = response.data.viewDataPelanggan
                this.pelangganMasukDataSet.viewPelangganMasukEndval = response.data.viewDataPelanggan.pelangganMasuk

            });
        },

    },
};


</script>

<template>
    <div class="row">
        <div class="col-md-6 col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" dir="ltr" width="70" height="40" :options="growthChartOptions"
                            :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">

                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="pelangganMasukDataSet.viewPelangganMasukEndval"
                                    :duration="2000"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Pelanggan Masuk</p>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span :class="{
                            'text-success me-1': pelangganMasukDataSet.persenGrowthPelangganMasuk > 0,
                            'text-danger me-1': pelangganMasukDataSet.persenGrowthPelangganMasuk < 0,
                            'text-info me-1': pelangganMasukDataSet.persenGrowthPelangganMasuk === 0
                        }">
                            <i :class="{
                                'mdi mdi-arrow-up-bold me-1': pelangganMasukDataSet.persenGrowthPelangganMasuk > 0,
                                'mdi mdi-arrow-down-bold me-1': pelangganMasukDataSet.persenGrowthPelangganMasuk < 0,
                            }">
                            </i>
                            <countTo :startVal="0" :endVal="pelangganMasukDataSet.persenGrowthPelangganMasuk"
                                :duration="1000" :decimals="2"></countTo>%
                        </span>
                        Sejak Sebulan Yang Lalu
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="float-end mr-4">
                        <apexchart class="apex-charts" dir="ltr" width="80" height="40" offsetY="50" :options="chartOptions"
                            :series="series"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">

                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="viewPelangganBaru.pelangganBaru" :duration="2000"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Pelanggan Baru</p>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span :class="{
                            'text-success me-1': persenGrowthPelangganBaru > 0,
                            'text-danger me-1': persenGrowthPelangganBaru < 0,
                            'text-info me-1': persenGrowthPelangganBaru === 0
                        }">
                            <i :class="{
                                'mdi mdi-arrow-up-bold me-1': persenGrowthPelangganBaru > 0,
                                'mdi mdi-arrow-down-bold me-1': persenGrowthPelangganBaru < 0,
                            }">
                            </i>
                            <countTo :startVal="0" :endVal="persenGrowthPelangganBaru" :duration="2000" :decimals="2">
                            </countTo>%
                        </span>
                        Sejak Sebulan Yang Lalu
                    </p>
                </div>
            </div>
        </div>


        <!-- end col-->
        <div class="col-md-6 col-xl-3">
            <router-link to="/invoices/list">
                <div class="card">
                    <div class="card-body">
                        <div class="float-end mt-2">
                            <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45"
                                :options="customerRadial" :series="orderseries"></apexchart>
                        </div>
                        <div>
                            <h4 class="mb-1 mt-1">
                                <span data-plugin="counterup">
                                    <countTo :startVal="0" :endVal="viewTotalInvoice" :duration="1000"></countTo>
                                </span>
                            </h4>
                            <p class="text-muted mb-0">Invoice Total</p>
                        </div>
                        <p class="text-muted mt-3 mb-0">
                            <span :class="{
                                'text-success me-1': persenGrowthInvoice > 0,
                                'text-danger me-1': persenGrowthInvoice < 0,
                                'text-info me-1': persenGrowthInvoice === 0
                            }">
                                <i :class="{
                                    'mdi mdi-arrow-up-bold me-1': persenGrowthInvoice > 0,
                                    'mdi mdi-arrow-down-bold me-1': persenGrowthInvoice < 0,
                                }">
                                </i>
                                <countTo :startVal="0" :endVal="persenGrowthInvoice" :duration="1000" :decimals="2">
                                </countTo>%
                            </span>
                            Sejak Sebulan Yang Lalu
                        </p>

                    </div>
                </div>
            </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
            <div class="card">
                <div class="card-body">
                    <div class="float-end mt-2">
                        <apexchart class="apex-charts" type="radialBar" dir="ltr" width="45" height="45"
                            :options="orderRadial" :series="customerseries"></apexchart>
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="viewTotalPaidInvoices" :duration="1000"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Invoice Terbayar</p>
                    </div>
                    <p class="text-muted mt-3 mb-0">
                        <span :class="{
                            'text-success me-1': persenGrowthInvoicePaid > 0,
                            'text-danger me-1': persenGrowthInvoicePaid < 0,
                            'text-info me-1': persenGrowthInvoicePaid === 0
                        }">
                            <i :class="{
                                'mdi mdi-arrow-up-bold me-1': persenGrowthInvoicePaid > 0,
                                'mdi mdi-arrow-down-bold me-1': persenGrowthInvoicePaid < 0,
                            }">
                            </i>
                            <countTo :startVal="0" :endVal="persenGrowthInvoicePaid" :duration="1000" :decimals="2">
                            </countTo>%
                        </span>
                        Sejak Sebulan Yang Lalu
                    </p>
                </div>
        </div>
    </div>

    <!-- end col-->
</div>
<!-- end row--></template>
